import React, { FC, useState, useCallback } from 'react';

import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Modal from 'molecules/Modal';
import {
  ModalContent,
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { palette } from 'utils/styles/variables';
import {
  paymentCompanyOptions1,
  paymentCompanyOptions2,
} from 'organisms/CompanyDetailsCard/data';
import {
  StyledListSubheader,
  StyledMenuItem,
  StyledSelectValue,
} from 'organisms/Companies/Companies.styles';
import { neutrals } from 'utils/styles/color';
import { log } from 'utils/helpers/logger';
import axios from 'axios';
import { AlertContainer } from 'utils/styles/AlertWrapper';

const CurrencySetModal: FC<{
  hsId: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ hsId, open, onClose, onSubmit }) => {
  const [error, setError] = useState<string>('');
  const [value, setValue] = useState<string | null>(null);

  const handleChangeCurrency = useCallback(
    (event: SelectChangeEvent<HTMLInputElement>) => {
      const { value: nextValue } = event.target as HTMLInputElement;
      setValue(nextValue);
    },
    []
  );

  const handleClose = useCallback(() => {
    onClose();
    setValue(null);
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    if (!value) return;

    try {
      const [region, currency] = value.split('-');
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`,
        { currency, region }
      );
      onSubmit();
      setValue(null);
    } catch (e) {
      if (e.response?.data?.error) {
        setError(e.response.data.error);
      }
      log(e);
    }
  }, [onSubmit, value, hsId]);

  function getInputValue(selected: string) {
    const [country, currency] = selected.split('-');
    if (country === 'usa') {
      return { currency, country: 'USA Quickbooks' };
    }

    return { currency, country: 'Ireland Quickbooks' };
  }

  return (
    <Modal
      padding24
      open={open}
      maxWidth="sm"
      title="Select QuickBooks account currency for the company"
      subtitle={
        <>
          After selecting QuickBooks account and its currency, customer record
          will be created in QuickBooks and will be synced with company in
          Zazmic-Connect.
          <br />
          <span style={{ color: palette.error[600] }}>
            WARNING: This action can’t be undone or changed!
          </span>
        </>
      }
      onClose={handleClose}
    >
      <>
        <ModalContent>
          {error && (
            <Box
              sx={{
                mb: '16px',
                alignItems: 'center ',
                '.MuiPaper-root': {
                  minWidth: 'auto !important',
                  width: 'auto !important',
                },
              }}
            >
              <AlertContainer>
                <Alert severity="error">{error}</Alert>
              </AlertContainer>
            </Box>
          )}
          <FormControl
            sx={{ m: 1, width: '-webkit-fill-available' }}
            variant="outlined"
          >
            <InputLabel shrink htmlFor="grouped-select">
              Currency
            </InputLabel>
            <Select
              size="small"
              id="grouped-select"
              label="Currency"
              input={
                <OutlinedInput value={value || ''} notched label="Currency" />
              }
              onChange={handleChangeCurrency}
              renderValue={(selectValue) => {
                // eslint-disable-next-line
                // @ts-ignore
                const { currency, country } = getInputValue(selectValue);
                return (
                  <StyledSelectValue $color={neutrals[400]}>
                    <span>{currency}</span>
                    <span>{country}</span>
                  </StyledSelectValue>
                );
              }}
            >
              <StyledListSubheader>USA QuickBooks</StyledListSubheader>
              {paymentCompanyOptions1.map(({ value: optionLabel, code }) => (
                <StyledMenuItem value={code}>
                  <span>{optionLabel}</span>
                </StyledMenuItem>
              ))}
              <StyledListSubheader>Ireland QuickBooks</StyledListSubheader>
              {paymentCompanyOptions2.map(({ value: optionLabel, code }) => (
                <StyledMenuItem value={code}>
                  <span>{optionLabel}</span>
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalActions $padding24>
          <ModalCloseButton variant="outlined" autoFocus onClick={handleClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            variant="contained"
            disabled={!value}
            onClick={handleSubmit}
          >
            Select
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CurrencySetModal;
