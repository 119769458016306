import styled from 'styled-components';
import { MenuItem, Select, InputLabel, Box } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const StyledSelect = styled(Select)`
  && {
    font-size: 1rem;
    font-weight: 400;
    color: ${neutrals[700]} !important;
    .MuiSelect-select {
      font-family: 'Poppins', sans-serif;
      padding: 0 !important;
      color: ${neutrals[700]};
      text-align: center;
      &:focus {
        background-color: transparent;
      }
    }
  }
`;

export const StyledLabel = styled(InputLabel)`
  && {
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    transform: translate(50%, 17px);
    color: ${neutrals[500]} !important;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-family: 'Poppins', sans-serif;
    color: ${neutrals[700]} !important;
    font-size: 1rem;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

export const StyledSelectValue = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    span:last-of-type {
      color: ${neutrals[400]};
    }
  }
`;
