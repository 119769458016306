const invoiceOptions = [
  { value: 'open', label: 'Paid vs Outstanding' },
  {
    value: 'old_companies',
    label: 'Companies vs Companies with at least one invoice in a month',
  },
  {
    value: 'new_companies',
    label:
      'New Companies vs New Companies with at least one invoice in a month',
  },
];

const invoiceTypeOptions = [
  { value: 'all', label: 'All types of invoices' },
  { value: 'gcp', label: 'GCP invoices' },
  { value: 'co', label: 'CloudOps invoices' },
];

// const invoiceCurrencyOptions = [
//   { value: 'USD', label: 'USD' },
//   { value: 'EUR', label: 'Euro' },
//   { value: 'GBP', label: 'GBP' },
// ];

const invoiceCurrencyOptions = [
  {
    label: 'USA QuickBooks',
    values: [
      { label: 'USD', value: 'usa-USD' },
      { label: 'CAD', value: 'usa-CAD' },
    ],
  },
  {
    label: 'Ireland QuickBooks',
    values: [
      { label: 'EUR', value: 'irl-EUR' },
      { label: 'GBP', value: 'irl-GBP' },
      { label: 'USD', value: 'irl-USD' },
    ],
  },
];

export { invoiceOptions, invoiceTypeOptions, invoiceCurrencyOptions };
