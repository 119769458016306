import React, { ReactNode, useRef, useState } from 'react';

import {
  IconButton,
  Tooltip,
  // SelectChangeEvent,
  FormControl,
  Box,
} from '@mui/material';
import {
  StyledSelect,
  // StyledMenuItem,
  StyledLabel,
  StyledSelectValue,
} from './StatusSelect.styles';
// import { statusType } from 'utils/constants/invoiceStatus';
import { IconTextFieldWrapper } from 'molecules/IconTextField/IconTextField.styles';
import { palette } from 'utils/styles/variables';

import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import {
  StyledGroupedMenuItem,
  StyledItemText,
  StyledListSubheader,
} from 'molecules/Select/Select.styles';

interface dataFormProps {
  label?: string;
  onChange?: (arg0: string) => void;
  shouldNoCurrencyHidden?: boolean;
}

// const currencyOptions = [
//   { key: 'USD', value: 'USD' },
//   { key: 'EUR', value: 'EUR' },
//   { key: 'GBP', value: 'GBP' },
//   { key: 'Not set', value: 'no_currency' },
// ];
const currencyOptions = [
  {
    label: 'USA QuickBooks',
    values: [
      { label: 'USD', value: 'usa-USD' },
      { label: 'CAD', value: 'usa-CAD' },
    ],
  },
  {
    label: 'Ireland QuickBooks',
    values: [
      { label: 'EUR', value: 'irl-EUR' },
      { label: 'GBP', value: 'irl-GBP' },
      { label: 'USD', value: 'irl-USD' },
    ],
  },
];

const CurrencyStatusSelect = ({
  shouldNoCurrencyHidden,
  label,
  onChange,
}: dataFormProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string>('');

  const boxRef = useRef(null);

  // const handleSortBy = (event: SelectChangeEvent<unknown>) => {
  //   const newStatus = event.target.value as statusType;
  //
  //   setStatusFilter(newStatus);
  //   if (onChange) {
  //     onChange(newStatus);
  //   }
  // };
  const handleItemClick = (
    option: (typeof currencyOptions)[0]['values'][0]
  ) => {
    setStatusFilter(option.value);
    if (onChange) {
      onChange(option.value);
    }
  };

  const handleClickIcon = () => {
    setTimeout(() => {
      setOpen((isOpen) => !isOpen);
    }, 100);

    // if (onChange) onChange('all');
  };

  const handleClick = () => {
    setOpen((isOpen) => (isOpen ? false : !statusFilter));
    setStatusFilter('');

    if (onChange) onChange('all');
  };

  function getInputValue(selected: string) {
    if (!selected) return { currency: 'USD', country: 'USA' };

    const [country, currency] = selected.split('-');
    if (currency === 'no_currency') {
      return { currency: 'Not set', country: '' };
    }
    if (country === 'usa') {
      return { currency, country: 'USA' };
    }

    return { currency, country: 'Irl' };
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <IconTextFieldWrapper>
        <Tooltip title={label || 'Filter by currency'}>
          <IconButton
            aria-label="close"
            // onClick={handleClickIcon}
            onMouseDown={handleClickIcon}
            sx={{
              p: '0 !important',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disabled={open}
          >
            <CurrencyIcon width={24} height={24} color={palette.primary.main} />
          </IconButton>
        </Tooltip>

        <FormControl
          variant="standard"
          sx={{
            minWidth: 220,
            display: open || statusFilter ? 'flex' : 'none',
          }}
          size="small"
        >
          <StyledLabel
            id="filter-label"
            shrink={false}
            sx={{ transform: 'translate(35%, 17px) !important' }}
          >
            {statusFilter ? '' : 'Select currency'}
          </StyledLabel>
          <StyledSelect
            open={open}
            value={statusFilter}
            id="filter-label"
            labelId="filter-label"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            // onChange={handleSortBy}
            MenuProps={{
              container: boxRef.current,
              className: 'currency-status-select',
              hideBackdrop: true,
              onBlur: () => {
                setTimeout(() => setOpen(false), 150);
              },
              sx: {
                position: 'unset',
                '.MuiPaper-root': {
                  minWidth: '220px !important',
                  width: 220,
                },
              },
            }}
            IconComponent={() =>
              statusFilter ? (
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleClick();
                  }}
                >
                  <CloseIcon
                    color={palette.error.main}
                    width={16}
                    height={16}
                  />
                </IconButton>
              ) : null
            }
            renderValue={(selectValue: unknown): ReactNode => {
              // eslint-disable-next-line
              // @ts-ignore
              const { currency, country } = getInputValue(selectValue);
              return (
                <StyledSelectValue>
                  <span>{currency}</span>
                  <span>{country}</span>
                </StyledSelectValue>
              );
            }}
          >
            {currencyOptions?.map(({ label: optionLabel, values }) => (
              <Box key={optionLabel}>
                <StyledListSubheader key={optionLabel}>
                  {optionLabel}
                </StyledListSubheader>
                {values.map((option) => (
                  <StyledGroupedMenuItem
                    key={option.value}
                    value={option.value}
                    onClick={() => handleItemClick(option)}
                  >
                    <StyledItemText>{option.label}</StyledItemText>
                  </StyledGroupedMenuItem>
                ))}
              </Box>
            ))}
            {!shouldNoCurrencyHidden && (
              <StyledGroupedMenuItem
                value="-no_currency"
                onClick={() =>
                  handleItemClick({ label: 'Not set', value: '-no_currency' })
                }
              >
                <StyledItemText sx={{ marginLeft: '-16px !important' }}>
                  Not set
                </StyledItemText>
              </StyledGroupedMenuItem>
            )}
          </StyledSelect>
        </FormControl>
      </IconTextFieldWrapper>
      {/* Container for menu paper */}
      <Box
        sx={{
          display: open ? 'block' : 'none',
          width: '240px',
          height: '260px',
          position: 'absolute',
          top: '26px',
          right: '-10px',
        }}
      >
        <Box
          ref={boxRef}
          sx={{
            zIndex: 10,
            position: 'relative',
            width: '100%',
            height: '100%',
            '.MuiPaper-root': {
              top: '0 !important',
              left: `10px !important`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CurrencyStatusSelect;
