import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import { Box, Stack, Typography } from '@mui/material';
import Block from 'molecules/Card';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { MonthDetails } from 'organisms/InvoiceChart/InvoiceChart';
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  CartesianGrid,
} from 'recharts';

import { formatLargeNumber } from 'utils/helpers/currencyFormatter';
import PastDueChart from 'organisms/PastDueChart/PastDueChart';
import {
  StyledFilterContainer,
  StyledHorizontalBar,
  StyledMonthDetails,
} from 'organisms/InvoiceChart/InvoiceChart.styles';
import { setStatusFilter } from 'store/invoices/invoicesSlice';
import { useBaoDispatch } from 'utils/hooks/redux';
import Select from 'molecules/Select';
import SelectGrouped from 'molecules/Select/SelectGrouped';
import {
  invoiceCurrencyOptions,
  invoiceOptions,
  invoiceTypeOptions,
} from 'organisms/InvoiceChart/data';
import { neutrals } from 'utils/styles/color';

interface InvoiceChartState {
  total_payment: number;
  total_outstanding: number;
  month: string;
}

interface CompanyChartState {
  month: string;
  companies_with_invoices: number;
  total_companies: number;
  year: number;
}

const MonthCompanyDetails = ({
  details,
  isNew,
}: {
  details: CompanyChartState | null;
  isNew: boolean;
}) => {
  if (!details) return null;

  const { total_companies, companies_with_invoices } = details;

  return (
    <StyledMonthDetails $largeLabel>
      <Box>
        <Typography variant="body2">
          {isNew ? 'New companies' : 'Companies'}:
        </Typography>
        <Typography variant="body2">{total_companies ?? 0}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          {isNew ? 'New companies' : 'Companies'} with invoices:
        </Typography>
        <Typography variant="body2">{companies_with_invoices ?? 0}</Typography>
      </Box>
    </StyledMonthDetails>
  );
};

// eslint-disable-next-line
const BarWithBorder = (fillColor: string) => (props: any) => {
  const {
    fill,
    x = 0,
    y = 0,
    width,
    height = 0,
    total_payment,
    total_outstanding,
  } = props;

  const totalPaymentPercent =
    (total_payment * 100) / (total_payment + total_outstanding) || 0;
  const totalPaymentHeight = (height * totalPaymentPercent) / 100 || 0;
  const heightDiff = height - totalPaymentHeight + 4 || 4;

  return (
    <g>
      <rect
        x={x}
        y={y}
        rx={5}
        ry={5}
        width={width}
        height={height}
        fill={fill}
      />
      <rect
        x={x}
        y={y + 4}
        width={width}
        height={height - 4 < 0 ? 0 : height - 4}
        fill={fill}
      />
      <rect
        x={x}
        y={y + heightDiff}
        width={width}
        height={height - heightDiff < 0 ? 0 : height - heightDiff}
        fill={fillColor || fill}
      />
    </g>
  );
};

const GlobalInvoiceChart = () => {
  const dispatch = useBaoDispatch();

  const [focusedBar, setFocusedBar] = useState<
    InvoiceChartState | CompanyChartState | null
  >(null);
  const [invoices, setInvoices] = useState<Array<InvoiceChartState>>([]);
  const [companies, setCompanies] = useState<Array<CompanyChartState>>([]);
  const [spacing, setSpacing] = useState<number>(0);

  const [filter, setFilter] = useState({
    status: 'open',
    type: 'all',
    currency: 'USD',
    region: 'usa',
  });

  const handleChangeFilter = (value: string, name?: string) => {
    if (!name) return;
    setFilter({ ...filter, [name]: value });
  };

  const handleChangeCurrency = (value: string, name?: string) => {
    if (!name) return;
    const [region, currency] = value.split('-');
    setFilter({ ...filter, region, currency });
  };

  useEffect(() => {
    fetchPageData();
  }, [filter]);

  async function fetchPageData() {
    try {
      if (filter.status === 'open') {
        dispatch(setStatusFilter('open'));

        const response: AxiosResponse<{ items: InvoiceChartState[] }> =
          await axios.get(
            `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/payment-and-outstanding`,
            {
              params: {
                num_of_months: 12,
                currency: filter.currency,
                region: filter.region,
                invoice_type: filter.type,
              },
            }
          );

        const items = response.data.items.map((item) => ({
          ...item,
          total: item.total_payment + item.total_outstanding ?? 0,
        }));

        setInvoices(items as Array<InvoiceChartState>);
      } else {
        const response: AxiosResponse<{ items: CompanyChartState[] }> =
          await axios.get(
            `${process.env.REACT_APP_REPORT_SERVICE}/company/count`,
            {
              params: {
                currency: filter.currency,
                region: filter.region,
                new_company: filter.status === 'new_companies',
              },
            }
          );

        setCompanies(response.data.items as Array<CompanyChartState>);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  function getInputValue(selected: string) {
    if (!selected) return { currency: 'USD', country: 'USA' };

    const [country, currency] = selected.split('-');
    if (country === 'usa') {
      return { currency, country: 'USA' };
    }

    return { currency, country: 'Irl' };
  }

  return (
    <Block
      title="Statistics"
      avatar={<CardAvatar />}
      action={
        filter.status === 'open' ? (
          <MonthDetails
            details={focusedBar as InvoiceChartState}
            currency={filter.currency}
            totalLabel="paid"
          />
        ) : (
          <MonthCompanyDetails
            details={focusedBar as CompanyChartState}
            isNew={filter.status === 'new_companies'}
          />
        )
      }
      sx={{ position: 'relative', height: '100%' }}
    >
      <Stack gap="12px">
        <StyledFilterContainer>
          <Select
            name="status"
            defaultValue="open"
            options={invoiceOptions}
            onSelect={handleChangeFilter}
          />
          {filter.status === 'open' && (
            <Select
              name="type"
              defaultValue="all"
              options={invoiceTypeOptions}
              onSelect={handleChangeFilter}
            />
          )}
          <SelectGrouped
            name="currency"
            defaultValue="usa-USD"
            onSelect={handleChangeCurrency}
            options={invoiceCurrencyOptions}
            renderValue={(selectValue: string) => {
              // eslint-disable-next-line
              // @ts-ignore
              const { currency, country } = getInputValue(selectValue);
              return (
                <>
                  <span>{currency}</span>
                  <span>{country}</span>
                </>
              );
            }}
          />
        </StyledFilterContainer>

        {filter.status === 'open' ? (
          <Box height={190}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={invoices}
                barGap={16}
                onMouseMove={(state: {
                  activeTooltipIndex?: number;
                  isTooltipActive?: boolean;
                }) => {
                  const { isTooltipActive, activeTooltipIndex } = state;
                  if (isTooltipActive) {
                    setFocusedBar(
                      invoices[activeTooltipIndex as number] || null
                    );
                  } else {
                    setFocusedBar(null);
                  }
                }}
                onMouseLeave={() => setFocusedBar(null)}
              >
                <CartesianGrid vertical={false} />
                <Bar
                  stackId="a"
                  dataKey="total"
                  fill="#FEDEDE"
                  barSize={40}
                  radius={[5, 5, 0, 0]}
                  shape={BarWithBorder('#F5BABA')}
                  activeBar={BarWithBorder('#F5BABA')}
                />
                <XAxis
                  dataKey="month"
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0.5,
                    color: neutrals[700],
                  }}
                  tickFormatter={(tick) => tick.substring(0, 3)}
                />
                <YAxis
                  width={spacing}
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0.5,
                    color: neutrals[700],
                  }}
                  tickCount={5}
                  tickFormatter={(value) => {
                    const displayValue = formatLargeNumber(
                      Number(value),
                      filter.currency
                    );
                    const yAxisSpace = 30 + displayValue.length * 3;
                    setSpacing((prev) =>
                      prev > yAxisSpace ? prev : yAxisSpace
                    );

                    return displayValue;
                  }}
                />
                <Tooltip
                  cursor={{ fill: '#A1A8B2', opacity: 0.3, type: '' }}
                  content={() => ''}
                  labelFormatter={() => ''}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box height={190}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={companies}
                barGap={8}
                onMouseMove={(state: {
                  activeTooltipIndex?: number;
                  isTooltipActive?: boolean;
                }) => {
                  const { isTooltipActive, activeTooltipIndex } = state;
                  if (isTooltipActive) {
                    setFocusedBar(
                      companies[activeTooltipIndex as number] || null
                    );
                  } else {
                    setFocusedBar(null);
                  }
                }}
                onMouseLeave={() => setFocusedBar(null)}
              >
                <CartesianGrid vertical={false} />
                <Bar
                  dataKey="total_companies"
                  fill="#F5BABA"
                  barSize={16}
                  radius={[5, 5, 0, 0]}
                  activeBar={{ fill: '#F5BABA', strokeWidth: 2 }}
                />
                <Bar
                  dataKey="companies_with_invoices"
                  fill="#FEDEDE"
                  barSize={16}
                  radius={[5, 5, 0, 0]}
                  activeBar={{ fill: '#FEDEDE', strokeWidth: 2 }}
                />
                <XAxis
                  dataKey="month"
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0.5,
                    color: neutrals[700],
                  }}
                  tickFormatter={(tick) => tick.substring(0, 3)}
                />
                <YAxis
                  width={30}
                  tick={{
                    fontSize: 12,
                    stroke: neutrals[700],
                    strokeWidth: 0.5,
                    color: neutrals[700],
                  }}
                />
                <Tooltip
                  cursor={{ fill: '#A1A8B2', opacity: 0.3, type: '' }}
                  content={() => ''}
                  labelFormatter={() => ''}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}

        <StyledHorizontalBar>
          {filter.status === 'open' && (
            <PastDueChart currency={filter.currency} />
          )}
        </StyledHorizontalBar>
      </Stack>
    </Block>
  );
};

export default GlobalInvoiceChart;
